:root {
  --left-table-column-width: 120px;

  --background-color-default-brightness: 3%;
  --background-color-light-brightness: 6%;
  --border-brightness: 9%;

  --background-color-default: hsl(225 50% var(--background-color-default-brightness) / 1);
  --background-color-light: hsl(225 50% var(--background-color-light-brightness) / 1);
  --border-color: hsl(225 25% var(--border-brightness) / 1);
}
#claimButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
main > div {
  /* border-collapse: collapse; */
  /* width: 100%; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
table {
  border-collapse: collapse;
  width: 100vw;
  max-width: 640px;
  background-color: var(--background-color-default);
  border: 1px solid var(--border-color);
}
table tr {
  /* width: 100%; */
  /* border: 1px solid; */
}

table a:hover,
table a:hover > div {
  color: #fff;
}
table #controls {
  display: none;
  color: #fff;
}
table #controls > button {
  flex: 1;
  padding: 0 24px;
}
table aside {
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 16px;
  color: grey;
  display: inline;
  vertical-align: middle;
  text-align: center;
  padding: 16px;
}
table button {
  user-select: none;
  appearance: none;
  border: none;
  background: 0 0;
  cursor: pointer;
  outline: 0;
  height: 48px;
  color: currentColor;
}
table button div {
  text-transform: uppercase;
  /* white-space: nowrap; */
  word-break: break-all;
  text-rendering: geometricPrecision;
  color: #fff;
}
table hr {
  background-color: #80808040;
  height: 1px;
  border: none;
}
table svg path {
  fill: currentColor;
}
table td div {
  word-break: break-all;
}
table th div {
  text-align: right;
  color: #80808080;
}
table td,
table th {
  padding: 8px;
  text-rendering: geometricPrecision;
  /* max-width: 320px; */
  line-height: 1.25;
  color: #808080;
}
table td div {
  /* width: calc(100vw - var(--left-table-column-width)); */
  /* white-space: nowrap; */
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
table td div svg ~ div {
  width: unset;
}
table tr:first-of-type > * {
  padding-top: 24px;
}
table tr:last-child td div > div {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
table tr:last-of-type > * {
  padding-bottom: 24px;
}
table tr#additional-details-border > * {
  padding: 0;
  margin: 0;
}
table[data-claim-rendered] {
  /* border: 1px solid #80808020; */
  /* border-right: 0; */
  /* border-left: 0; */
}
table[data-claim-rendered] #controls {
  display: flex;
}
table[data-claim-rendered] button {
  opacity: 0.5;
}
table[data-claim-rendered] button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
table[data-claim-rendered] button:hover {
  background-color: #80808020;
  opacity: 1;
}
table[data-claim-rendered] button > div {
  display: none;
}
table[data-claim-rendered] button:disabled > svg.claim-icon {
  display: none;
}
table[data-claim-rendered] button:hover > div {
  display: unset;
  color: #fff;
}
table[data-claim-rendered] button:hover > svg {
  display: none !important;
}
table[data-claim-rendered] button#additionalDetails {
  width: 100%;
  color: #fff;
  /* opacity: 0.25; */
}
table[data-claim-rendered] button#additionalDetails:hover {
  /* opacity: 1; */
}
table[data-claim-rendered] tr {
  background-color: #80808000;
  transition: 125ms ease-in-out opacity;
}
table[data-claim-rendered] tr#additional-details-border {
  /* background-color: #80808010; */
  background-color: var(--background-color-light);
  /* -webkit-backdrop-filter: blur(4px); */
  /* backdrop-filter: blur(4px); */
  /* border: 1px solid #80808020; */
  /* border-left: 0; */
  /* border-right: 0; */
}
table[data-claim-rendered] tr#additional-details-border + tr > * {
  padding-top: 24px;
}
table #additional-details-border ~ tr,
table[data-details-visible="false"] #additional-details-border ~ tr {
  opacity: 0;
  pointer-events: none;
  display: none;
}
table[data-details-visible="false"] {
  /* border-bottom: 1px solid #80808000; */
}
table[data-details-visible="true"] #additional-details-border ~ tr {
  opacity: 1;
  pointer-events: unset;
  display: table-row;
  /* background-color: #80808010; */
  background-color: var(--background-color-light);
  /* -webkit-backdrop-filter: blur(4px); */
  /* backdrop-filter: blur(4px); */
}
table[data-details-visible="true"] {
  /* border-bottom: 1px solid #80808020; */
}
table[data-contract-loaded] #Token {
  display: none;
}
#transferDetails\.requestedAmount > a {
  color: #fff;
  font-size: 24px;
}
table[data-details-visible="false"] #permit\.permitted\.token .full,
table[data-details-visible="true"] #permit\.permitted\.token .short {
  display: none;
}
table[data-details-visible="false"] #permit\.permitted\.token .short,
table[data-details-visible="true"] #permit\.permitted\.token .full {
  display: initial;
}
table[data-details-visible="false"] #transferDetails\.to .full,
table[data-details-visible="true"] #transferDetails\.to .short {
  display: none;
}
table[data-details-visible="false"] #transferDetails\.to .short,
table[data-details-visible="true"] #transferDetails\.to .full {
  display: initial;
}
#To > td,
#To > th {
  padding-bottom: 24px;
}
table thead {
  display: table-row-group;
}
table tbody {
  display: none;
}
table[data-claim="none"] thead {
  display: table-row-group;
}
table[data-claim="error"] thead {
  display: table-row-group;
}
table[data-claim="ok"] thead {
  display: none;
}
table[data-claim="none"] tbody {
  display: none;
}
table[data-claim="error"] tbody {
  display: none;
}
table[data-claim="ok"] tbody {
  display: table-row-group;
}
/*
table[data-claim-rendered="true"][data-claim="none"][data-contract-loaded="true"][data-details-visible="false"] {
  border: none;
} */
#transferDetails\.to a div {
  opacity: 0.66;
}
#transferDetails\.to a:hover div {
  opacity: 1;
}
#transferDetails\.to div {
  color: #fff;
}

.show-cl {
  display: block;
}

.hide-cl > svg.claim-loader {
  display: none;
}
.show-cl > svg.claim-loader {
  display: unset;
}
.hide-cl > svg.claim-icon {
  display: unset;
}
.show-cl > svg.claim-icon {
  display: none;
}

#additionalDetails svg {
  /* display: none; */
}
table[data-details-visible="true"] #additionalDetails svg.opener {
  display: none;
}
table[data-details-visible="false"] #additionalDetails svg.opener {
  display: unset;
}

table[data-details-visible="true"] #additionalDetails svg.closer {
  display: unset;
}
table[data-details-visible="false"] #additionalDetails svg.closer {
  display: none;
}

table .full {
  /* word-break: break-all; */
}

td#owner > a {
  word-break: break-all;
}

table td {
  padding-right: 16px;
}
table th {
  padding-left: 16px;
}
